export const navItemsData = [
  {
    path: "/",
    name: "Accueil",
  },
  {
    path: "/nos-prestas",
    name: "Nos prestations",
  },
  {
    path: "/vente",
    name: "Vente",
  },
  {
    path: "/contact",
    name: "Contact",
  },
]
